import { useState } from 'react';

const CategoryForm = ({ submitHandler, initialState, isFetching }) => {
	const defaultState = {
		name: '',
	};

	const [formData, setFormData] = useState(initialState || defaultState);

	const preSubmit = (e) => {
		e.preventDefault();
		const data = formData;
		setFormData(initialState);
		submitHandler(data);
	};

	const changeHandler = ({ target }) => {
		setFormData({ ...formData, [target.name]: target.value });
	};

	return (
		<form
			onSubmit={preSubmit}
			className='w-50 mt-5'
		>
			<fieldset>
				<legend>Create a new transaction category</legend>
				<label
					htmlFor='name'
					className='form-label mt-3'
				>
					Category Name
				</label>
				<input
					type='text'
					id='name'
					name='name'
					className='form-control'
					onChange={changeHandler}
					value={formData.name}
					required
				/>
				<button
					type='submit'
					className='btn btn-outline-primary mt-3'
				>
					{isFetching ? (
						<>
							<span className='spinner-border spinner-border-sm'></span>
							<span> Loading...</span>
						</>
					) : (
						'Submit'
					)}
				</button>
			</fieldset>
		</form>
	);
};

export default CategoryForm;
