import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import SummaryBase from "./SummaryBase";

const Summary = () => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				exact={true}
				path={`${path}/`}
			>
				<SummaryBase />
			</Route>
		</Switch>
	);
};

export default Summary;
