import React, { useState } from 'react';

const CounterpartyForm = ({ formState, submitHandler, isFetching }) => {
	const initialState = {
		name: '',
		address: '',
	};

	const [formData, setFormData] = useState(formState || initialState);

	const changeHandler = ({ target }) => {
		setFormData({ ...formData, [target.name]: target.value });
	};

	const preSubmit = (e) => {
		e.preventDefault();
		submitHandler(formData);
	};

	return (
		<form onSubmit={preSubmit} className='w-50'>
			<fieldset>
				<legend>Create a new counterparty</legend>
				<label htmlFor='name' className='form-label'>
					Name
				</label>
				<input
					className='form-control'
					id='name'
					name='name'
					type='text'
					onChange={changeHandler}
					value={formData.name}
					required
				/>
				<label htmlFor='name' className='form-label'>
					Address
				</label>
				<input
					className='form-control'
					id='address'
					name='address'
					type='text'
					onChange={changeHandler}
					value={formData.address}
					required
				/>

				<button type='submit' className='btn btn-primary mt-3'>
					{isFetching ? (
						<>
							<span className='spinner-border spinner-border-sm'></span>
							<span> Loading...</span>
						</>
					) : (
						'Submit'
					)}
				</button>
			</fieldset>
		</form>
	);
};

export default CounterpartyForm;
