import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import Dashboard from '../dashboard/Dashboard';
import Transactions from '../transactions/Transactions';
import Counterparties from '../counterparties/Counterparties';
import { NotFound } from './NotFound';
import Categories from '../categories/Categories';
import Properties from '../Properties/Properties';
import Summary from '../summary/Summary';

const Routes = withAuthenticationRequired(() => {
	return (
		<Switch>
			<Route
				exact
				path='/'
			>
				<Dashboard />
			</Route>
			<Route path='/transactions'>
				<Transactions />
			</Route>
			<Route path='/counterparties'>
				<Counterparties />
			</Route>
			<Route path='/categories'>
				<Categories />
			</Route>
			<Route path='/properties'>
				<Properties />
			</Route>
			<Route path='/summary'>
				<Summary />
			</Route>
			<Route>
				<NotFound />
			</Route>
		</Switch>
	);
});

export default Routes;
