import { Link } from 'react-router-dom';
import Spinner from '../components/common/Spinner';
import { useProperties } from '../util/useAPI';
import PropertiesTable from './PropertiesTable';

const PropertiesList = () => {
	const { properties, propertiesIsLoading } = useProperties();

	if (propertiesIsLoading) return <Spinner />; 
    
	console.log(properties);
	return (
		<>
			<h1>Properties</h1>
			<p>A list of all properties on record</p>
			<Link
				to='/properties/new'
				className='btn btn-outline-primary'
			>
				New Property
			</Link>
            <PropertiesTable properties={properties} />
		</>
	);
};

export default PropertiesList;
