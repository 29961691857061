import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import NewTransaction from './NewTransaction';
import EditTransaction from './EditTransaction';
import TransactionsList from './TransactionsList';

const Transactions = () => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route
				path={`${path}/new`}
				exact={true}
			>
				<NewTransaction />
			</Route>
			<Route
				exact={true}
				path={`${path}/:transaction_id/edit`}
			>
				<EditTransaction />
			</Route>
			<Route
				exact={true}
				path={`${path}/`}
			>
				<TransactionsList />
			</Route>
		</Switch>
	);
};

export default Transactions;
