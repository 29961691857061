import { usePost } from '../util/useAPI'
import PropertyForm from './PropertyForm'

const CreateProperty = () => {
    const success = () => {
        console.log(response);
    }
    
    const {isFetching, fetchData, response} = usePost('properties', success);
    
    const submitHandler = (data) => {
        fetchData(data);
    }
    
    
	return (
        <PropertyForm submitHandler={submitHandler} isFetching={isFetching}/>
    );
};

export default CreateProperty;
