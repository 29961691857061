import React from 'react';
import { Link } from 'react-router-dom'

export const NotFound = () => {
    return (
        <div>
            <h1>Page Not Found</h1>
            <p>Check your URL or go <Link to={'/'}>Home</Link></p>
        </div>
    )
}