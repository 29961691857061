import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import EditCounterparty from './EditCounterparty';
import CreateCounterparty from './CreateCounterparty';
import CounterpartiesList from './CounterpartiesList';

const Counterparties = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}`} exact>
                <CounterpartiesList />
            </Route>
            <Route path={`${path}/new`}>
                <CreateCounterparty />
            </Route>
            <Route path={`${path}/:counterpartyId/edit`}>
                <EditCounterparty />
            </Route>
		</Switch>
	);
};

export default Counterparties;
