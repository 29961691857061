import { useHistory } from 'react-router-dom';

const CounterpartiesTable = ({ counterparties }) => {
	const hx = useHistory();
	if (!counterparties.length)
		return (
			<>
				<p>No saved counterparties.</p>
			</>
		);

	const rows = counterparties
		.sort((a, b) => (a.name - b.name))
		.map((el) => (
			<tr
				key={el.id}
				onClick={() => hx.push(`/counterparties/${el.id}/edit`)}
			>
				<td>{el.name}</td>
				<td>{el.address}</td>
			</tr>
		));

	return (
		<table className='table table-striped table-hover w-75'>
			<thead>
				<tr>
					<th>Name</th>
					<th>Address</th>
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	);
};

export default CounterpartiesTable;
