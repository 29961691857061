import { useCounterparties } from '../util/useAPI';
import { Link } from 'react-router-dom';

import CounterpartiesTable from './CounterpartiesTable';
import Spinner from '../components/common/Spinner';

const CounterpartiesList = () => {
	const { counterparties, counterpartiesIsLoading } = useCounterparties();

	if (counterpartiesIsLoading) return <Spinner />;

	return (
		<>
			<h1>Counterparties</h1>
			<p>A list of all counterparties on record</p>
			<Link
				to='/counterparties/new'
				className='btn btn-outline-primary mt-3 mb-3'
			>
				New Counterparty
			</Link>
			<CounterpartiesTable counterparties={counterparties} />
		</>
	);
};

export default CounterpartiesList;
