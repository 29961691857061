import CategoriesTable from './CategoriesTable';
import { Link } from 'react-router-dom';

const CategoriesList = () => {
	return (
		<>
			<h1>All Categories</h1>
			<Link
				to='/categories/new'
				className='btn btn-outline-primary mt-3 mb-3'
			>
				New Category
			</Link>
			<p>A list of all categories</p>
			<CategoriesTable />
		</>
	);
};

export default CategoriesList;
