import React from 'react';
import { useHistory } from 'react-router-dom';

const PropertiesTable = ({ properties }) => {
	const hx = useHistory();
	if (!properties.length)
		return <p className='mt-3'>No properties saved yet...</p>;
	let rows = null;
	if (properties && properties.length) {
		rows = properties.map((property) => (
			<tr key={property.id}>
				<td>{property.address_1}</td>
				<td>{property.address_2}</td>
				<td>{property.city}</td>
				<td>{property.state}</td>
				<td>{property.zip}</td>
				<td>
					<button
						className='btn btn-sm btn-outline-primary'
						onClick={() =>
							hx.push(`/properties/${property.id}/edit`)
						}
					>
						Edit
					</button>
				</td>
			</tr>
		));
	}

	return (
		<table className='table table-striped table-hover mt-3'>
			<thead>
				<tr>
					<th>Address 1</th>
					<th>Address 2</th>
					<th>City</th>
					<th>State</th>
					<th>Zip Code</th>
					<th>Edit</th>
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	);
};

export default PropertiesTable;
