import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AuthButton from '../components/auth/AuthButton';

const Menu = ({ headingText }) => {

	return (
		<nav className='navbar navbar-expand-md'>
			<Link
				to='/'
				className='navbar-brand'
			>
				Ledger{' '}
				<span className='fw-lighter fs-6'>v1.0.1</span>
			</Link>
			<button
				className='navbar-toggler'
				type='button'
				data-bs-toggle='collapse'
				data-bs-target='#navbarNav'
				aria-controls='navbarNav'
				aria-expanded='false'
				aria-label='Toggle navigation'
			>
				<span className='navbar-toggler-icon'></span>
			</button>
			<div
				className='collapse navbar-collapse d-flex justify-content-end'
				id='navbarNav'
			>
				<ul className='navbar-nav'>
					<li className='nav-item'>
						<Link
							className='nav-link'
							to='/transactions'
						>
							Transactions
						</Link>
					</li>
					<li className='nav-item'>
						<Link
							className='nav-link'
							to='/counterparties'
						>
							Counterparties
						</Link>
					</li>
					<li className='nav-item'>
						<Link
							className='nav-link'
							to='/categories'
						>
							Categories
						</Link>
					</li>
					<li className='nav-item'>
						<Link
							className='nav-link'
							to='/properties'
						>
							Properties
						</Link>
					</li>
					<li className='nav-item'>
						<Link
							className='nav-link'
							to='/summary'
						>
							Summary
						</Link>
					</li>
				</ul>
				<AuthButton />
			</div>
		</nav>
	);
};

export default Menu;
