import React from 'react';
import TransactionForm from './TransactionForm';
import Spinner from '../components/common/Spinner';
import { useParams } from 'react-router';
import { useTransactions, usePatch, useDelete } from '../util/useAPI';
import { DateTime as dt } from 'luxon';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
const EditTransaction = () => {
	const hx = useHistory();
	const success = () => {
		toast.success('Data updated successfully');
		hx.push('/transactions');
	};
	const { transaction_id } = useParams();
	const { data, status } = useTransactions();
	const { isFetching, fetchData } = usePatch('transactions', success);
    const {deleteData} = useDelete('transactions', success);

	if (status === 'fetching') return <Spinner />;
	const transaction = data.find((el) => el.id === Number(transaction_id));

	const submitHandler = (data) => {
		data = { ...data, id: Number(transaction.id) };
		fetchData(data, transaction.id);
	};
    
    const deleteHandler = () => {
        deleteData(Number(transaction.id));
    }

	const initialFormData = transaction && {
		amount: transaction.amount,
		counterparty_id: transaction.counterparty_id,
		category_id: transaction.category_id,
		is_verified: transaction.is_verified,
		execution_date: dt
			.fromISO(transaction.execution_date)
			.setZone('utc')
			.toISODate(),
            property_id: transaction.property_id,
	};

	return (
		<>
			<h1>Edit Transaction</h1>
			<div
				className='alert alert-danger w-50'
				role='alert'
			>
				<div>
					<span className='bold'>DANGER:</span> Any changes here will
					overwrite the existing record.
				</div>
			</div>
			<TransactionForm
				initialState={initialFormData}
				submitHandler={submitHandler}
                deleteHandler={deleteHandler}
			/>
		</>
	);
};

export default EditTransaction;
