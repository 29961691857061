import { useState } from 'react';
import { DateTime as dt } from 'luxon';

import SummaryForm from './SummaryForm';
import { useTransactions } from '../util/useAPI';
import SummaryList from './SummaryList';
import Spinner from '../components/common/Spinner';

const SummaryBase = () => {
    // Default State
    const today = dt.now();
    const defaultState = {
        dateStart: dt.now().minus({ years: 1 }),
        dateEnd: today,
        includeUnverified: false,
    };

    // Filter State
    const [filterState, setFilterState] = useState(defaultState);

    // Raw Data
    const { data, status } = useTransactions();

    // Figure out if the API has returned data
    const isLoading = status === 'fetching';

    // Map property data
    const getPropertyMap = (data) => {
        const propertyMap = new Map();
        data.forEach((item) => {
            if (!propertyMap.has(item.address_1)) {
                propertyMap.set(item.address_1, []);
            }
            propertyMap.get(item.address_1).push(item);
        });
        return propertyMap;
    };

    // Build an array of table data
    const getTableData = (propertyMap) => {
        // tableData is an array (of objects)
        const tableData = [];
        // Go through each property and create a category map
        propertyMap.forEach((property, address) => {
            // create a new category map for each property
            const categoryMap = new Map();

            // Go through each property and add the totals of each category to the category map
            property.forEach((item) => {
                if (!filterState.includeUnverified && !item.is_verified) return;
                const itemDate = dt.fromISO(item.execution_date);
                if (itemDate >= filterState.dateStart && itemDate <= filterState.dateEnd) {
                    if (!categoryMap.has(item.category)) {
                        categoryMap.set(item.category, item.amount);
                        return;
                    }
                    const aggregate = (parseFloat(categoryMap.get(item.category)) + parseFloat(item.amount)).toFixed(2);
                    categoryMap.set(item.category, aggregate);
                }
            });
            tableData.push({ property: address, itemsByCategory: categoryMap });
        });
        return tableData;
    };

    // Format table data to be sent to tableList component
    const tableData = getTableData(getPropertyMap(data));

    // Handle form changes
    const filterChangeHandler = ({ target }) => {
        if (target.name === 'includeUnverified') {
            return setFilterState({ ...filterState, includeUnverified: target.checked });
        }
        setFilterState({ ...filterState, [target.name]: dt.fromISO(target.value) });
    };

    // Handle Submit from form (not used for now)
    const filterSubmitHandler = (data) => {
        throw new Error('not implemented');
    };

    return (
        <>
            <h1>Summary</h1>
            <p>A summary of all records by category between</p>
            <SummaryForm filterChangeHandler={filterChangeHandler} filterSubmitHandler={filterSubmitHandler} filterState={filterState} />
            {tableData ? <SummaryList isLoading={isLoading} tableData={tableData} /> : <Spinner />}
        </>
    );
};

export default SummaryBase;
