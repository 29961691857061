const SummaryTable = ({ tableData, tableName }) => {
    // If there is no data, tell client
    if (!tableData) return 'No data';

    // Prepare table rows
    const rows = [];
    tableData.forEach((value, key) =>
        rows.push(
            <tr key={key}>
                <th>{key}</th>
                <td>${value}</td>
            </tr>
        )
    );
    return (
        <>
            <h2 className="mt-5">{tableName}</h2>
            <table className='table'>
                <thead>
                    <tr>
                        <th scope='col'>Category</th>
                        <th scope='col'>Aggregate Amount</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        </>
    );
};

export default SummaryTable;
