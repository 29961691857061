import Spinner from '../components/common/Spinner';
import { useCategories } from '../util/useAPI';
import { useHistory } from 'react-router-dom';

const CategoriesTable = () => {
	const { categories, categoriesIsLoading } = useCategories();
	const hx = useHistory();

	if (categoriesIsLoading) return <Spinner />;

	let rows;
	if (categories && categories.length) {
		rows = categories.map((el) => (
			<tr
				key={el.id}
				onClick={() => hx.push(`/categories/${el.id}/edit`)}
			>
				<td>{el.name}</td>
			</tr>
		));
	}
	return (
		<table className='table table-striped table-hover w-25'>
			<thead>
				<tr>
					<th>Name</th>
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	);
};

export default CategoriesTable;
