import React from 'react';
import { Switch, Route } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import CreateProperty from './CreateProperty';
import EditProperty from './EditProperty';
import PropertiesList from './PropertiesList';

const Properties = () => {
    
    const {path} = useRouteMatch();
    return ( 
    <Switch>
        <Route path={`${path}/new`} exact>
            <CreateProperty />
        </Route>
        <Route path={`${path}/:property_id/edit`}>
            <EditProperty />
        </Route>
        <Route path={`${path}/`} exact>
            <PropertiesList />
        </Route> 
    </Switch>
    );
}
 
export default Properties;