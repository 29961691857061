import SummaryTable from "./SummaryTable";

const SummaryList = ({ tableData }) => {
    if (!tableData.length) return;
    const tables = tableData.map(item => {
        return <SummaryTable tableName={item.property} tableData={item.itemsByCategory} key={item.property}/>
    });

    return tables;
}

export default SummaryList;