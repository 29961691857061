import { usePost } from '../util/useAPI';
import CategoryForm from './CategoryForm';
import Spinner from '../components/common/Spinner';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

const CreateCategory = () => {
	const hx = useHistory();

	const success = () => {
		hx.push('/categories');
		toast.success('Category was saved successfully');
	};

	const { isFetching, fetchData } = usePost('categories', success);

	const submitHandler = (data) => {
		fetchData(data);
	};

	return (
		<>
			<h1>New Category</h1>
			{isFetching ? (
				<Spinner />
			) : (
				<CategoryForm
					submitHandler={submitHandler}
					isFetching={isFetching}
				/>
			)}
		</>
	);
};

export default CreateCategory;
