import React from 'react';
import CounterpartyForm from './CounterpartyForm';
import { useHistory } from 'react-router-dom';
import { usePost } from '../util/useAPI';
import { toast } from 'react-hot-toast';

const CreateCounterparty = () => {
	const hx = useHistory();
	const success = () => {
		hx.push('/counterparties');
		toast.success('Counterparty saved successfully');
	};
	const { isFetching, fetchData } = usePost('counterparties', success);
	const submitHandler = (data) => {
		fetchData(data);
	};
	return (
		<>
			<h1>Create Counterparty</h1>
			<CounterpartyForm
				submitHandler={submitHandler}
				isFetching={isFetching}
			/>
		</>
	);
};

export default CreateCounterparty;
