import React from 'react';
import { Link } from 'react-router-dom';
import { useTransactions } from '../util/useAPI';

import TransactionsTable from '../components/common/TransactionTable';
import Spinner from '../components/common/Spinner';

const TransactionsList = () => {
	const { data, status } = useTransactions();

	let transactions;

	if (data && status === 'idle') transactions = data;

	return (
		<>
			<h1>All Transactions</h1>
			<p>All transactions that exist in the database</p>
			<Link
				to='/transactions/new'
				className='btn btn-outline-primary mt-3 mb-3'
			>
				New Transaction
			</Link>
			{status === 'fetching' ? (
				<Spinner />
			) : (
				<TransactionsTable transactions={transactions} />
			)}
		</>
	);
};

export default TransactionsList;
