import React, { useState } from 'react';
import { DateTime as dt } from 'luxon';

import Spinner from '../components/common/Spinner';
import {
	useCounterparties,
	useCategories,
	useProperties,
} from '../util/useAPI';

const TransactionForm = (props) => {
	const { counterparties, counterpartiesIsLoading } = useCounterparties();
	const { categories, categoriesIsLoading } = useCategories();
	const { properties, propertiesIsLoading } = useProperties();

	const initialFormData = {
		amount: '',
		category_id: '',
		counterparty_id: '',
		property_id: '',
		is_verified: false,
		execution_date: dt.now().toISODate(),
	};

	const [formData, setFormData] = useState(
		props.initialState || initialFormData
	);

	if (counterpartiesIsLoading || categoriesIsLoading || propertiesIsLoading)
		return <Spinner />;

	const preSubmit = (e) => {
		e.preventDefault();
		console.log(formData);
		const confirm = window.confirm(
			'Are you sure you want to post this transaction?'
		);
		if (confirm) {
			const pushData = formData;
			setFormData(initialFormData);
			props.submitHandler(pushData);
		}
	};

	const changeHandler = ({ target }) => {
		if (
			target.name === 'amount' ||
			target.name === 'category_id' ||
			target.name === 'counterparty_id' ||
			target.name === 'property_id'
		) {
			setFormData({ ...formData, [target.name]: Number(target.value) });
		} else if (target.name === 'is_verified') {
			setFormData({
				...formData,
				[target.name]: target.value === 'true',
			});
		} else {
			setFormData({ ...formData, [target.name]: target.value });
		}
	};

	const preDelete = (e) => {
        e.preventDefault();
		const confirm = window.confirm('WARNING\nThis process is permanent');
		if (confirm) props.deleteHandler();
	};

	return (
		<form
			onSubmit={preSubmit}
			className='w-50'
		>
			<fieldset>
				<legend>Transaction Information</legend>
				<label
					htmlFor='counterparty'
					className='form-label mt-2'
				>
					Counterparty
				</label>
				<select
					required
					id='counterparty'
					className='form-select'
					name='counterparty_id'
					value={formData.counterparty_id}
					onChange={changeHandler}
				>
					<option value=''>Select a counterparty</option>
					{counterparties.map((el) => (
						<option
							key={el.id}
							value={el.id}
						>
							{el.name}
						</option>
					))}
				</select>
				<label
					htmlFor='property_id'
					className='form-label mt-2'
				>
					Property
				</label>
				<select
					required
					id='property_id'
					name='property_id'
					className='form-select'
					value={formData.property_id}
					onChange={changeHandler}
				>
					<option value=''>Select a property</option>
					{properties.map((el) => (
						<option
							key={el.id}
							value={el.id}
						>
							{el.address_1}
						</option>
					))}
				</select>
				<label
					htmlFor='amount'
					className='form-label mt-2'
				>
					Amount
				</label>
				<div className='input-group'>
					<span className='input-group-text'>$</span>
					<input
						type='number'
						id='amount'
						className='form-control'
						name='amount'
						value={formData.amount}
						onChange={changeHandler}
						required
					/>
				</div>
				<label
					htmlFor='categories'
					className='form-label mt-2'
				>
					Category
				</label>
				<select
					required
					id='categories'
					className='form-select'
					name='category_id'
					value={formData.category_id}
					onChange={changeHandler}
				>
					<option value=''>Select a category</option>
					{categories.map((el) => (
						<option
							key={el.id}
							value={el.id}
						>
							{el.name}
						</option>
					))}
				</select>
				<label
					htmlFor='execution_date'
					className='form-label mt-2'
				>
					Execution Date
				</label>
				<input
					type='date'
					className='form-control'
					id='execution_date'
					name='execution_date'
					value={formData.execution_date}
					onChange={changeHandler}
					required
				/>
				<div className='form-check mt-3'>
					<label
						htmlFor='verified'
						className='form-label'
					>
						Transaction Verified
					</label>
					<input
						type='radio'
						className='form-check-input'
						id='verified'
						name='is_verified'
						onChange={changeHandler}
						checked={formData.is_verified}
						value={true}
					/>
				</div>
				<div className='form-check'>
					<label
						htmlFor='pending'
						className='form-check-label'
					>
						Pending
					</label>
					<input
						type='radio'
						className='form-check-input'
						name='is_verified'
						id='pending'
						checked={!formData.is_verified}
						onChange={changeHandler}
						value={false}
					/>
				</div>
				<div className='d-flex gap-2'>
					<button
						className='btn btn-primary mt-3'
						type='submit'
					>
						{props.isFetching ? (
							<>
								<span className='spinner-border spinner-border-sm'></span>
								<span> Loading...</span>
							</>
						) : (
							'Submit'
						)}
					</button>
					{props.deleteHandler ? (
						<button
							className='btn btn-outline-danger mt-3'
							onClick={preDelete}
						>
							Delete
						</button>
					) : null}
				</div>
			</fieldset>
		</form>
	);
};

export default TransactionForm;
