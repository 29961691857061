import { DateTime as dt } from 'luxon';

const SummaryForm = ({ filterChangeHandler, filterSubmitHandler, filterState }) => {
    return (
        <form onSubmit={filterSubmitHandler} className='w-50 mt-5'>
            <fieldset>
                <legend>Filter</legend>
                <div className="d-flex justify-content-start">
                    <div>
                        <label htmlFor='dateStart' className='form-label mt-2'>
                            Begin With Date
                        </label>
                        <input
                            type='date'
                            className='form-control'
                            id='dateStart'
                            name='dateStart'
                            value={filterState.dateStart.toISODate()}
                            onChange={filterChangeHandler}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor='dateEnd' className='form-label mt-2'>
                            End With Date
                        </label>
                        <input
                            type='date'
                            className='form-control'
                            id='dateEnd'
                            name='dateEnd'
                            value={filterState.dateEnd.toISODate()}
                            onChange={filterChangeHandler}
                            required
                        />
                    </div>
                </div>
                <div className='form-check mt-3'>
                    <label htmlFor='includeUnverified' className='form-label'>
                        Include Unverified Transactions
                    </label>

                    <input
                        type='checkbox'
                        className='form-check-input'
                        id='includeUnverified'
                        name='includeUnverified'
                        onChange={filterChangeHandler}
                        checked={filterState.includeUnverified}
                    />
                </div>
            </fieldset>
        </form>
    );
};

export default SummaryForm;
