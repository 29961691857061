import React from 'react';
import Menu from './Menu';
import Routes from './Routes';
import {Toaster} from 'react-hot-toast';

const Layout = () => {
    return (
        <div className='container'>
            <Menu />
            <Toaster />
            <Routes />
        </div>
    )
};

export default Layout;
