import { usePatch, useProperties } from '../util/useAPI';
import PropertyForm from './PropertyForm';
import { useParams } from 'react-router';
import Spinner from '../components/common/Spinner';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const EditProperty = () => {
	const hx = useHistory();
	const success = () => {
		toast.success('Property saved successfully');
		hx.push('/properties');
	};
	const { property_id } = useParams();
	const { properties, propertiesIsLoading } = useProperties();
	const { fetchData } = usePatch('properties', success);

	if (propertiesIsLoading) return <Spinner />;

	let property;
	if (properties.length) {
		property = properties.find((el) => el.id === Number(property_id));
	}

	const submitHandler = (data) => {
		const confirm = window.confirm(
			'Are you sure you want to make these changes?\nThis will affect all transactions that include this property'
		);
		if (confirm) fetchData(data, data.id);
	};

	return (
		<>
			<h1>Edit Property</h1>
			<PropertyForm
				initialState={property}
				submitHandler={submitHandler}
			/>
		</>
	);
};

export default EditProperty;
