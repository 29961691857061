import { Route, Switch, useRouteMatch } from 'react-router';

import CreateCategory from './CreateCategory';
import CategoriesList from './CategoriesList';
import EditCategory from './EditCategory';

const Categories = () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route
				path={`${path}/new`}
				exact
			>
				<CreateCategory />
			</Route>
			<Route
				path={`${path}/:categoryId/edit`}
				exact
			>
				<EditCategory />
			</Route>
			<Route
				path={`${path}/`}
				exact
			>
				<CategoriesList />
			</Route>
		</Switch>
	);
};

export default Categories;
