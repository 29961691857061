import { useState } from 'react';

const PropertyForm = ({ submitHandler, initialState }) => {
	const defaultState = {
		address_1: '',
		address_2: '',
		city: '',
		state: '',
		zip: '',
	};
	const [formData, setFormData] = useState(initialState || defaultState);

	const changeHandler = ({ target }) => {
		if (target.name === 'zip')
			setFormData({ ...formData, zip: Number(target.value) });
		else setFormData({ ...formData, [target.name]: target.value });
	};

	const preSubmit = (e) => {
		e.preventDefault();
		console.log(formData);
		submitHandler(formData);
	};

	return (
		<form
			onSubmit={preSubmit}
			className='w-50'
		>
			<fieldset>
				<legend>Create a new property</legend>
				<label
					htmlFor='address_1'
					className='form-label'
				>
					Address line 1
				</label>
				<input
					className='form-control'
					type='text'
					name='address_1'
					id='address_1'
					onChange={changeHandler}
					value={formData.address_1}
					required
				/>
				<label
					htmlFor='address_2'
					className='form-label'
				>
					Address line 2
				</label>
				<input
					className='form-control'
					type='text'
					name='address_2'
					id='address_2'
					onChange={changeHandler}
					value={formData.address_2}
				/>
				<label
					htmlFor='city'
					className='form-label'
				>
					City
				</label>
				<input
					className='form-control'
					type='text'
					name='city'
					id='city'
					onChange={changeHandler}
					value={formData.city}
					required
				/>
				<label
					htmlFor='state'
					className='form-label'
				>
					State
				</label>
				<input
					className='form-control'
					type='text'
					name='state'
					id='state'
					onChange={changeHandler}
					value={formData.state}
					maxLength='2'
					minLength='2'
					required
				/>
				<label
					htmlFor='zip'
					className='form-label'
				>
					Zip
				</label>
				<input
					className='form-control'
					type='number'
					name='zip'
					id='zip'
					onChange={changeHandler}
					value={formData.zip}
					required
				/>
			</fieldset>
			<button
				type='submit'
				className='btn btn-outline-primary mt-3'
			>
				Submit
			</button>
		</form>
	);
};

export default PropertyForm;
