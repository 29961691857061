import React from 'react';
import { Link } from 'react-router-dom';

import Spinner from '../components/common/Spinner';
import TransactionTable from '../components/common/TransactionTable';
import { useTransactions } from '../util/useAPI';

const Dashboard = () => {
	const { data, status } = useTransactions();

	if (status === 'fetching') return <Spinner />;

	let transactions;

	if (data && data.length) {
		transactions = data
			.filter((el) => !el.is_verified)
			.sort((a, b) => a.date - b.date);
	}

	return (
		<div className='pt-3'>
			<h1>Welcome to the Ledger!</h1>
			<Link
				to='/transactions/new'
				className='btn btn-outline-primary mt-4 mb-5'
			>
				New Transaction
			</Link>
			<p>
				{transactions && transactions.length
					? `There are ${transactions.length} transactions that are not verified`
					: `All transaction are verified - nothing to display here`}
			</p>
			<TransactionTable transactions={transactions} />
		</div>
	);
};

export default Dashboard;
