import {useEffect, useState} from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useUser = () => {
    const [token, setToken] = useState('');
    const {getAccessTokenSilently} = useAuth0();
    
    useEffect(() => {
        const getToken = async() => {
            const token = await getAccessTokenSilently();
            return token;
        }
        getToken().then((res) => setToken(res));
    }, [getAccessTokenSilently]);
    
    return token;
}