import React from 'react';
import TransactionForm from './TransactionForm';
import { usePost } from '../util/useAPI';
import { useHistory } from 'react-router';
import { toast } from 'react-hot-toast';

const NewTransaction = () => {
	const hx = useHistory();
	const success = () => {
		hx.push('/');
		toast.success('Data saved successfully');
	};
	const { fetchData, isFetching } = usePost('transactions', success);
	const submitHandler = (data) => {
		fetchData(data);
	};
	return (
		<>
			<h1>New Transaction</h1>
			<TransactionForm
				isFetching={isFetching}
				submitHandler={submitHandler}
			/>
		</>
	);
};

export default NewTransaction;
