import React from 'react';
import { DateTime as dt } from 'luxon';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { usePut } from '../../util/useAPI';

const TransactionTable = ({ transactions }) => {
	const success = () => {
		toast.success('Transaction is verified');
		hx.push('/transactions');
	};
	const { isFetching, fetchData } = usePut(`transactions`, success);
	const hx = useHistory();

	if (!transactions || !transactions.length) return null;

	const verifyTransaction = (id) => {
		fetchData(null, id);
	};

	const transactionRows = transactions
		.sort(
			(a, b) =>
				dt.fromISO(b.execution_date) - dt.fromISO(a.execution_date)
		)
		.map((transaction, i) => (
			<tr key={transaction.id}>
				<td>
					{dt
						.fromISO(transaction.execution_date)
						.setZone('utc')
						.toLocaleString()}
				</td>
				<td>{transaction.name}</td>
				<td>${transaction.amount.toFixed(2)}</td>
				<td>{transaction.category}</td>
				{transaction.is_verified ? (
					<td>Verified</td>
				) : (
					<td>
						<button
							onClick={() => verifyTransaction(transaction.id)}
							className='btn btn-sm btn-outline-primary'
						>
							{isFetching ? (
								<>
									<span className='spinner-border spinner-border-sm'></span>
									<span> Sending...</span>
								</>
							) : (
								'Verify'
							)}
						</button>
					</td>
				)}
				<td>
					<button
						onClick={() =>
							hx.push(`/transactions/${transaction.id}/edit`)
						}
						className='btn btn-outline-danger btn-sm'
					>
						Edit
					</button>
				</td>
			</tr>
		));

	return (
		<table className='table table-striped table-hover'>
			<thead>
				<tr>
					<th>Date</th>
					<th>Name</th>
					<th>Amount</th>
					<th>Category</th>
					<th>Verified</th>
					<th>Edit</th>
				</tr>
			</thead>
			<tbody>{transactionRows}</tbody>
		</table>
	);
};

export default TransactionTable;
