import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
const { REACT_APP_AUTH_DOMAIN, REACT_APP_CLIENT_ID, REACT_APP_AUDIENCE } =
	process.env;
root.render(
	<React.StrictMode>
		<Router>
			<Auth0Provider
				domain={REACT_APP_AUTH_DOMAIN}
				clientId={REACT_APP_CLIENT_ID}
				redirectUri={window.location.origin}
				audience={REACT_APP_AUDIENCE}
			>
				<App />
			</Auth0Provider>
		</Router>
	</React.StrictMode>
);
