import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useUser } from './useUser';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fetchJson = async (url, options, body, token, signal) => {
	const headers = {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/json',
	};

	body = body ? JSON.stringify({ data: body }) : null;

	try {
		const res = await fetch(url, {
			headers,
			signal,
			...options,
			body,
		});

		const response = res.status === 204 ? res : await res.json();
		if (response.error) {
			throw new Error(response.error);
		} else return response.data;
	} catch (err) {
		if (err.name !== 'AbortError') {
			console.error(err);
			throw new Error(err.message);
		} else console.error(err.name);
	}
};
export const useTransactions = () => {
	const [status, setStatus] = useState('idle');
	const [data, setData] = useState([]);
	const token = useUser();

	useEffect(() => {
		const url = `${BASE_URL}/transactions`;
		const options = { method: 'GET' };
		const abort = new AbortController();

		const fetchData = () => {
			setStatus('fetching');
			fetchJson(url, options, null, token, abort.signal)
				.then(setData)
				.then(() => setStatus('idle'));
		};

		if (token) fetchData();

		return () => abort.abort();
	}, [token]);

	return { status, data };
};

export const useCounterparties = () => {
	const [counterparties, setCounterparties] = useState([]);
	const [counterpartiesIsLoading, setCounterpartiesIsLoading] =
		useState(false);
	const token = useUser();

	useEffect(() => {
		const url = `${BASE_URL}/counterparties`;
		const options = { method: 'GET' };
		const abort = new AbortController();

		const fetchData = () => {
			setCounterpartiesIsLoading(true);
			fetchJson(url, options, null, token, abort.signal)
				.then(setCounterparties)
				.then(() => setCounterpartiesIsLoading(false));
		};

		if (token) fetchData();

		return () => abort.abort();
	}, [token]);

	return { counterparties, counterpartiesIsLoading };
};

export const useCategories = () => {
	const [categories, setCategories] = useState([]);
	const [categoriesIsLoading, setCategoriesIsLoading] = useState(false);
	const token = useUser();

	useEffect(() => {
		const url = `${BASE_URL}/categories`;
		const options = { method: 'GET' };
		const abort = new AbortController();

		const fetchData = () => {
			setCategoriesIsLoading(true);
			fetchJson(url, options, null, token, abort.signal)
				.then(setCategories)
				.then(() => setCategoriesIsLoading(false));
		};

		if (token) fetchData();

		return () => abort.abort();
	}, [token]);

	return { categoriesIsLoading, categories };
};

export const useProperties = () => {
	const [properties, setProperties] = useState([]);
	const [propertiesIsLoading, setPropertiesIsLoading] = useState(false);
	const token = useUser();

	useEffect(() => {
		const url = `${BASE_URL}/properties`;
		const options = { method: 'GET' };
		const abort = new AbortController();

		const fetchData = () => {
			setPropertiesIsLoading(true);
			fetchJson(url, options, null, token, abort.signal)
				.then(setProperties)
				.then(() => setPropertiesIsLoading(false));
		};

		if (token) fetchData();

		return () => abort.abort();
	}, [token]);

	return { propertiesIsLoading, properties };
};

export const usePost = (endpoint, callback) => {
	const [response, setResponse] = useState({});
	const [isFetching, setIsFetching] = useState(false);
	const token = useUser();
	if (!endpoint) return;
	const url = `${BASE_URL}/${endpoint}`;
	const options = { method: 'POST' };

	const fetchData = (body) => {
		setIsFetching(true);
		fetchJson(url, options, body, token)
			.then(setResponse)
			.then(() => setIsFetching(false))
			.then(callback)
			.catch((err) => toast.error(err.message));
	};

	return { isFetching, response, fetchData };
};

export const usePut = (endpoint, callback) => {
	const [response, setResponse] = useState({});
	const [isFetching, setIsFetching] = useState(false);
	const token = useUser();

	if (!endpoint) return;

	const url = `${BASE_URL}/${endpoint}`;
	const options = { method: 'PUT' };

	const fetchData = (body, id) => {
		setIsFetching(true);
		fetchJson(`${url}/${id}/verify`, options, body, token)
			.then(setResponse)
			.then(() => setIsFetching(false))
			.then(callback)
			.catch((err) => toast.error(err.message));
	};
	return { isFetching, response, fetchData };
};

export const usePatch = (endpoint, callback) => {
	const [response, setResponse] = useState({});
	const [isFetching, setIsFetching] = useState(false);
	const token = useUser();

	if (!endpoint) return;

	const url = `${BASE_URL}/${endpoint}`;
	const options = { method: 'PATCH' };

	const fetchData = (body, id) => {
		setIsFetching(true);
		fetchJson(`${url}/${id}`, options, body, token)
			.then((res) => setResponse(res))
			.then(() => setIsFetching(false))
			.then(callback)
			.catch((err) => toast.error(err.message));
	};
	return { isFetching, response, fetchData };
};

export const useDelete = (endpoint, callback) => {
	const [response, setResponse] = useState({});
	const [isFetching, setIsFetching] = useState(false);
	const token = useUser();

	if (!endpoint) return;

	const url = `${BASE_URL}/${endpoint}`;
	const options = { method: 'DELETE' };

	const fetchData = (id) => {
		setIsFetching(true);
		fetchJson(`${url}/${id}`, options, {id}, token)
			.then((res) => setResponse(res))
			.then(() => setIsFetching(false))
			.then(callback)
			.catch((err) => toast.error(err.message));
	};
	return { isFetching, response, deleteData: fetchData };
};