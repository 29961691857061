import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AuthButton = () => {
	const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
	let button;
	if (isAuthenticated) {
		button = (
			<button
				className='btn btn-outline-primary'
				onClick={() => logout({ returnTo: window.location.origin })}
			>
				Logout
			</button>
		);
	} else {
		button = (
			<button
				className='btn btn-primary'
				onClick={() => loginWithRedirect()}
			>
				Login
			</button>
		);
	}

	return button;
};

export default AuthButton;
