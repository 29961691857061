const EditCounterparty = () => {
	return (
        <>
            <h1>Edit Counterparty</h1>
            <p>Standby - this feature is coming soon...</p>
        </>
    );
};

export default EditCounterparty;
